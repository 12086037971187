import {twMerge} from '@/stylesheets/twMerge';
import Section, {
  type SectionProps,
} from '@/components/base/layouts/Section/Section';
import type {ButtonGroupProps} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Card, {type CardProps} from '@/components/base/modules/Card/Card';
import SideBySide, {
  type SideBySideProps,
} from '@/components/base/modules/SideBySide/SideBySide';
import SectionHeader, {
  type SectionHeaderProps,
} from '@/components/base/sections/SectionHeader/SectionHeader';
import type {Mode} from '@/components/base/types';

export interface SideBySideListProps {
  buttonGroup?: ButtonGroupProps;
  className?: string;
  componentName?: string;

  /**
   * Sets the `mode` property for all sub-components.
   */
  mode?: Mode;

  sectionHeader?: SectionHeaderProps;
  sectionProps?: SectionProps;
  sideBySideItems?: CardProps[];
  sideBySideProps?: Omit<SideBySideProps, 'children'>;

  /** @deprecated Use `sideBySideProps` instead */
  image?: SideBySideProps['image'];
  /** @deprecated Use `sectionProps` instead */
  sectionBottomSpacing?: SectionProps['bottomSpacing'];
  /** @deprecated Use `sectionProps` instead */
  sectionTopSpacing?: SectionProps['topSpacing'];
  /** @deprecated Use `sideBySideProps` instead */
  verticalAlign?: SideBySideProps['verticalAlign'];
  /** @deprecated Use `sideBySideProps` instead */
  visualPosition?: SideBySideProps['visualPosition'];
}

export default function SideBySideList({
  buttonGroup,
  className,
  componentName = 'side-by-side-list',
  image,
  mode = 'light',
  sectionBottomSpacing = '4xl',
  sectionHeader,
  sectionProps,
  sectionTopSpacing = '4xl',
  sideBySideItems,
  sideBySideProps,
  verticalAlign,
  visualPosition = 'left',
}: SideBySideListProps) {
  return (
    <Section
      bottomSpacing={sectionBottomSpacing}
      className={className}
      componentName={componentName}
      mode={mode}
      topSpacing={sectionTopSpacing}
      {...sectionProps}
    >
      <SectionHeader mode={mode} {...sectionHeader} />
      <SideBySide
        gutter="right"
        image={image}
        mode={mode}
        verticalAlign={verticalAlign}
        visualPosition={visualPosition}
        {...sideBySideProps}
      >
        <div className="grid gap-y-xl mt-4 md:mt-0">
          {sideBySideItems?.map((sideBySideItem, index) => (
            <Card
              key={`card-${index}`}
              mode={mode}
              {...sideBySideItem}
              size={sideBySideItem.size ?? 'small'}
              type={sideBySideItem.type ?? 'default'}
              horizontal={true}
            />
          ))}
          {buttonGroup && (
            <ButtonGroup
              mode={mode}
              {...buttonGroup}
              className={twMerge(
                'sm:ml-[88px] md:ml-24',
                buttonGroup.className,
              )}
            />
          )}
        </div>
      </SideBySide>
    </Section>
  );
}
